<template>
    <div id="main2" style="width:100%;height: 100%;"></div>
</template>

<script>
    import * as echarts from 'echarts';
    // import axios from "axios";

    export default {
        data() {
            return {
                num: []
            }
        },
        mounted() {
            this.initChart();
        },
        methods: {
            initChart() {
              this.$api.visual.getAllOrg().then(res => {
                this.initbie(res.data);
                this.num  = res.data;
              });
                // axios.get("http://localhost:9090/getAllOrg").then((result) => {
                //     console.log(result)
                //     this.initbie(result.data);
                //     // this.num = result.data
                // })
            },
            initbie(data) {
                var color = ["#004080", "#024a91", "#065aab", "#026eb6", "#066eab", "#0682ab", "#088ab5", "#07acc5", "#0cadc5", "#18bbc7",
                    "#08c0b6", "#03cec3", "#06c8be", "#06c8ab", "#06dcab", "#06f0ab", "#0edeb8", "#11eeac", "#4af5c2", "#7dffcf"];
                var chartDom = document.getElementById('main2');
                var myChart = echarts.init(chartDom);
                var option;
                option = {
                    title: {
                        text: '单位分布',
                        // subtext: 'Fake Data',
                        left: 'center',
                        textStyle: {
                            color: "rgba(255,255,255,0.9)",
                            fontSize: "20"
                        }
                    },
                    tooltip: {
                        trigger: 'item'
                    },
                    color: color,
                    legend: {
                        itemWidth: 10,
                        itemHeight: 10,
                        orient: 'horizontal',
                        x: 'center',
                        y: '60%',
                        textStyle: {
                            color: "rgba(255,255,255,.5)",
                            fontSize: "12"
                        },
                    },
                    series: [
                        {
                            name: '单位',
                            type: 'pie',
                            radius: ['30%', '50%'],
                            center: ["50%", "35%"],
                            data: data,
                            itemStyle: {
                                borderRadius: 3,
                                borderColor: '#fff',
                                borderWidth: 0.5
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    show: false
                                },
                                labelLine: {
                                    show: false
                                }
                            }
                        }
                    ]
                };

                option && myChart.setOption(option);


            }
        }
    }


</script>