<template>
    <div class="content">
        <div
            ref="charts"
            style="width: 150%; height: 150%; margin:0 auto"
        ></div>
    </div>
</template>

<script>
import * as echarts from 'echarts'
import china from '@/assets/js/china.json'
import axios from "axios";
import {ref} from "vue";
import {number} from "echarts";

export default {
    data() {
        return {
            pointData:[
                {name:'安徽省',value:[117.194778,31.86577]},
                { name: '北京市', value: [116.403694, 39.949459] },
                { name: '重庆市', value: [106.553263, 29.556681] },
                { name: '福建省', value: [119.292069, 26.144144] },
                { name: '甘肃省', value: [103.856737, 36.094212] },
                { name: '广东省', value: [113.239359, 23.185545] },
                { name: '广西省', value: [108.345678, 22.861984] },
                { name: '贵州省', value: [106.616332, 26.707352] },
                { name: '海南省', value: [110.350983, 19.968035] },
                { name: '河北省', value: [114.508772, 38.083783] },
                { name: '河南省', value: [113.644099, 34.769161] },
                { name: '黑龙江省', value: [126.522207, 45.801617] },
                { name: '湖北省', value: [114.361594, 30.601078] },
                { name: '湖南省', value: [112.926605, 28.217167] },
                { name: '吉林省', value: [125.326383, 43.797768] },
                { name: '江苏省', value: [118.832137, 32.038322] },
                { name: '江西省', value: [115.851775, 28.672488] },
                { name: '辽宁省', value: [123.486653, 41.682522] },
                { name: '内蒙古', value: [111.785972, 40.849642] },
                { name: '宁夏省', value: [106.257585, 38.482579] },
                { name: '青海省', value: [101.851432, 36.622494] },
                { name: '山东省', value: [117.194778, 36.652148] },
                { name: '山西省', value: [112.595453, 37.858034] },
                { name: '陕西省', value: [109.026378, 34.350591] },
                { name: '上海市', value: [121.518142, 31.211845] },
                { name: '四川省', value: [104.132697, 30.561282] },
                { name: '天津市', value: [117.286764, 39.001295] },
                { name: '西藏', value: [91.144205, 29.649484] },
                { name: '新疆', value: [87.667116, 43.817754] },
                { name: '云南省', value: [102.881681, 24.866897] },
                { name: '浙江省', value: [120.211934, 30.274265] },
                { name: '香港', value: [114.242011, 22.272474] },
                { name: '澳门', value: [113.579709, 22.169692] },
                { name: '台湾', value: [121.591732, 25.034634] },
            ],
            points: [ // 散点图数据
                { name: '江苏省', value: [118.832137, 32.038322], itemStyle: {color: '#00EEFF'}},
            ],
            linesData: [// 线条数据
                {coords: [[118.832137, 32.038322], [116.403694, 39.949459]]},// 江苏->北京
            ],
            planePath: // 飞机svg
                'path://M1705.06,1318.313v-89.254l-319.9-221.799l0.073-208.063c0.521-84.662-26.629-121.796-63.961-121.491c-37.332-0.305-64.482,36.829-63.961,121.491l0.073,208.063l-319.9,221.799v89.254l330.343-157.288l12.238,241.308l-134.449,92.931l0.531,42.034l175.125-42.917l175.125,42.917l0.531-42.034l-134.449-92.931l12.238-241.308L1705.06,1318.313z',
        }
    },
    mounted() {
        this.initCharts()
    },
    methods: {
        initCharts() {
            // axios.get("http://localhost:9090/getMap").then((result) =>{
            this.$api.visual.getMap().then(result => {
                const data=result.data
                data.forEach((item)=>{
                    const lineData={
                        coords:[[],[]]
                    }
                    for (let i = 0;i<this.pointData.length;i++){
                        const item1 = this.pointData[i]
                        if(item1.name ===item.Destination){
                            lineData.coords[1]=item1.value
                        }
                        if(item1.name ===item.Source){
                            const location =item1.value
                            const singlePoint={
                                name:'',
                                value:[],
                                itemStyle:{}
                            }
                            lineData.coords[0]=location
                            singlePoint.name=item.Source
                            singlePoint.value=location
                            singlePoint.itemStyle={color: '#00EEFF'}
                            this.points.push(singlePoint)
                        }
                    }
                    this.linesData.push(lineData)
                })
                const uniqueNames = new Set();
                this.points = this.points.filter(point => {
                    if (!uniqueNames.has(point.name)) {
                        uniqueNames.add(point.name);
                        return true;
                    }
                    return false;
                })
                console.log(this.points)
                // this.num = result.data
                const charts = echarts.init(this.$refs['charts'])
                const option = {
                    // backgroundColor: '#0E2152',// 背景颜色
                    geo: {// 地图配置
                        map: 'china',
                        label: { // 图形上的文本标签
                            normal: {// 通常状态下的样式
                                show: true,
                                textStyle: {
                                    color: '#fff'
                                }
                            },
                            emphasis: {// 鼠标放上去高亮的样式
                                textStyle: {
                                    color: '#fff'
                                }
                            }
                        },
                        itemStyle: {// 地图区域的样式设置
                            normal: { // 通常状态下的样式
                                borderColor: '#5089EC',
                                borderWidth: 1,
                                areaColor: { //地图区域的颜色
                                    type: 'radial', // 径向渐变
                                    x: 0.5, // 圆心
                                    y: 0.5,// 圆心
                                    r: 0.8,// 半径
                                    colorStops: [
                                        { // 0% 处的颜色
                                            offset: 0,
                                            color: 'rgba(0, 102, 154, 0)'
                                        },
                                        { // 100% 处的颜色
                                            offset: 1,
                                            color: 'rgba(0, 102, 154, .4)'
                                        }
                                    ]
                                }
                            },
                            // 鼠标放上去高亮的样式
                            emphasis: {
                                areaColor: '#2386AD',
                                borderWidth: 0
                            }
                        }
                    },
                    series: [
                        { // 散点系列数据
                            type: 'effectScatter',// 带有涟漪特效动画的散点（气泡）图
                            coordinateSystem: 'geo', //该系列使用的坐标系:地理坐标系
                            // 特效类型,目前只支持涟漪特效'ripple'，意为“涟漪”
                            effectType: 'ripple',
                            // 配置何时显示特效。可选'render'和'emphasis' 。
                            showEffectOn: 'render',
                            rippleEffect: { // 涟漪特效相关配置。
                                period: 10, // 动画的周期，秒数。
                                scale: 4,// 动画中波纹的最大缩放比例。
                                // 波纹的绘制方式，可选 'stroke' 和 'fill'。
                                brushType: 'fill'
                            },
                            zlevel: 1, // 所有图形的 zlevel 值。
                            data: this.points
                        },
                        { // 线条系列数据
                            type: 'lines',
                            zlevel: 2,
                            symbol: ['none', 'arrow'], // 标记的图形: 箭头
                            symbolSize: 10, // 标记的大小
                            effect: { // 线条特效的配置
                                show: true,
                                period: 6, // 特效动画的时间，单位s
                                trailLength: 0, // 特效尾迹的长度。取值[0,1]值越大，尾迹越重
                                symbol: this.planePath, // 特效图形的标记 可选'circle'等
                                symbolSize: 15// 特效标记的大小
                            },
                            lineStyle: { // 线条样式
                                normal: {
                                    color: '#93EBF8',
                                    width: 2.5, // 线条宽度
                                    opacity: 0.6, // 尾迹线条透明度
                                    curveness: 0.2// 尾迹线条曲直度
                                }
                            },
                            data: this.linesData
                        }
                    ]
                }
                // 地图注册，第一个参数的名字必须和option.geo.map一致
                echarts.registerMap('china', china)
                charts.setOption(option)
            })
        }
    }
}
</script>
<style scoped>
    .content {
        background-color: transparent;
        height: 100%;
    }
</style>