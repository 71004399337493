<template>
    <div class="app-container box">
        <div class="header">
            <Hd></Hd>
            <div class="title">
                <span>江苏高擎论文评审中心数据监控平台</span>
            </div>
        </div>
        <div class="left">
            <div class="s1 panel">
                <Ms></Ms>
            </div>
            <div class="s2 panel">
                 <Sn></Sn>
                <div class="panel-footer"></div>
            </div>
        </div>
        <div class="center">
            <div class="top">
                <div class="item item1"><Sg></Sg></div>
            </div>
            <div class="zyz"><Mp></Mp></div>
            <div class="s3 panel">
                <Pn></Pn>
            </div>
        </div>
        <div class="right">
            <div class="l2 panel"><Fk></Fk></div>
            <div class="l3 panel">
                <org></org>
            </div>
        </div>
    </div>
</template>

<script>
// import Tt from './components/TodaySubmit.vue'
    // import Te from './components/TodayHasDone.vue'
    import Sn from '@/components/monitor/SubjectDistribution.vue'
    import Pn from '@/components/monitor/PaperPerMon.vue'
    import Sg from '@/components/monitor/Submitting.vue'
    import Title from '@/components/monitor/Title.vue'
    import org from '@/components/monitor/Org.vue'
    import Fk from '@/components/monitor/Feedback.vue'
    import Mp from '@/components/monitor/Map.vue'
    import Hd from '@/components/monitor/Header.vue'
    import Ms from '@/components/monitor/MatchRes.vue'
    export default {
        name: 'Monitor',
        components:{
            Hd,
            Ms,
            Sn,
            Pn,
            Sg,
            // Tt,
            // Te,
            Title,
            org,
            Fk,
            Mp,
        },
        data(){
            return {

            };
        },

        mounted() {
        },
        methods: {

        }
    }
</script>


<style scoped lang="less">
  #app{
    margin: 0;
    padding: 0;
    border: 0;
  }
  .box {
    background: url("../../../src/assets/img/bg.jpg");
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position:fixed;
    background-size: 100% 100%;

    .header{
      position: absolute;
      height: 10%;
      width: 100%;
      background: url("../../../src/assets/img/line(1).png") rgba(255, 255, 255, 0.04);
      .title{
        //width: 100%;
        margin-left: 40%;
        text-align: center;
        position: absolute;
        span{
          color: white;
          font-size: 20px;
          line-height: 70px;
        }
      }
    }

    .panel {
      border: 1px solid rgba(25, 186, 139, 0.17);
      background: url("../../../src/assets/img/line(1).png") rgba(255, 255, 255, 0.04);
      padding: 0 0.15rem 0.4rem;

      &::before {
        position: absolute;
        top: 0px;
        left: 0px;
        content: "";
        width: 10px;
        height: 10px;
        border-left: 2px solid #02a6b5;
        border-top: 2px solid #02a6b5;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        width: 10px;
        height: 10px;
        border-right: 2px solid #02a6b5;
        border-top: 2px solid #02a6b5;
      }

      .panel-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        &::before {
          position: absolute;
          bottom: 0px;
          left: 0px;
          content: "";
          width: 10px;
          height: 10px;
          border-left: 2px solid #02a6b5;
          border-bottom: 2px solid #02a6b5;
        }

        &::after {
          position: absolute;
          bottom: 0;
          right: 0;
          content: "";
          width: 10px;
          height: 10px;
          border-right: 2px solid #02a6b5;
          border-bottom: 2px solid #02a6b5;
        }
      }
    }

    .center {
      width: 40%;
      display: flex;
      flex-direction: column;
      margin-top: 5%;

      .top {

        height: 15%;
        display: flex;
        justify-content: center;
        .item {
          position: relative;
          width: 33%;
        }
      }
      .zyz {
        height: 55%;
        position: relative;
        left: -25%;
        top: -20%;
      }
      .s3 {
        height: 30%;

      }
    }

    .left {
      margin-top: 5%;
      width: 30%;
      display: flex;
      flex-direction: column;

      .s1 {
        height: 55%;
        text-align: center;
      }
      .s2 {
        height: 45%;
      }
    }

    .right {
      margin-top: 5%;
      width: 30%;
      display: flex;
      flex-direction: column;
      .l2 {
        height: 55%;
        text-align: center;
        //width: 100%;
      }
      .l3 {
        height: 45%;
        display: flex;
        justify-content: center;
        .it {
          width: 50%;
        }
      }
    }
  }
</style>