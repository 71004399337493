<template>
    <div class="container">
        <el-table :data="tableData1" ref="table" height="100%"
                  :header-cell-style="{color:'#e9e9e9',borderColor:'#1d3a76'}"
                  :cell-style="{color:'#e9e9e9',borderColor:'#1d3a76'}"
                  :show-overflow-tooltip="true"
                  style="width: 100%;">
            <el-table-column prop="paperTitle" align="center" label="论文名称" width="250"></el-table-column>
            <el-table-column prop="paperSubjectMajor" align="center" label="学科" width="100"></el-table-column>
            <el-table-column prop="resultScore" align="center" label="准确度" width="80"></el-table-column>
        </el-table>
    </div>
</template>

<script>

export default {
    data() {
        return {
            tableData1: [],
            table1_pageNum: 1,
            table1_pageSize: 400,
            // scrolltimer: '', // 自动滚动的定时任务
            // status: true,
        };
    },
    created() {
        this.$api.visual.getMatchRes(this.table1_pageNum, this.table1_pageSize).then(res => {
            this.tableData1 = res.data;
        });
    },
    mounted() {
        this.infiniteScroll();
        this.start();
    },
    methods: {
        infiniteScroll() {
            const table = this.$refs.table;
            const divData = table.bodyWrapper;

            let t;
            function start() {
                t = setInterval(() => {
                    divData.scrollTop += 1;
                    if (divData.clientHeight + divData.scrollTop === divData.scrollHeight) {
                        divData.scrollTop = 0;
                    }
                }, 40);
            }

            this.start = start; // 将start()方法挂载到组件实例上，以便在其他方法中调用

            divData.addEventListener('mouseenter', () => {
                clearInterval(t);
            });

            divData.addEventListener('mouseleave', () => {
                this.start(); // 调用组件实例上的start()方法，重新开始滚动
            });
        },
    },
};
</script>
<style scoped lang="less">
  .container{
    width: 100%;
    height: 100%;
  }
  /deep/.el-table {
    // width: 100%;
    box-sizing: border-box;
    &::before{
      height: 0px;
    }
  }
  .el-table--border::after, .el-table--group::after{
    width: 0px;
    height: 0px;
  }

  /deep/ .el-table__body .cell {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* 设置显示的行数 */
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 0 !important;
    background: transparent !important;
  }

  /*最外层透明*/
  /deep/ .el-table, /deep/ .el-table__expanded-cell{
    background-color: transparent;
  }
  /* 表格内背景颜色 */
  /deep/ .el-table th,
  /deep/ .el-table tr,
  /deep/ .el-table td {
    background-color: transparent;
  }
  :deep(.el-table__inner-wrapper::before) {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    z-index: 3;
  }
</style>
