<template>
    <div class="header">
        送审中数
        <div class="num">
       {{ num }}
    </div>
    </div>
    
    
    </template>
    
    <script>
    export default{
        data(){
            return{
                num:0
            }
        },
      created() {
        this.$api.visual.getAllSubmit().then(res => {
          console.log(res)
          this.num  = res.data;
        });
      },
    }
    
    
    </script>
    <style>
        .header{
            font-size: 15px;
            color: white;
            font-family: "Microsoft JhengHei Light";
            text-align: center;
            line-height: 20px;
        }
        .num{
            font-family: 'electronicFont';
            flex: 1;
            line-height: 70px;
            color: #ffeb7b;
            text-align: center;
            font-size: 30px;
            position: relative;
        }
    </style>