<template>
    <div class="header">
        <div class="select">
            <div class="select-option">
                <el-select style="width:100%"
                           :popper-append-to-body="false"
                           v-model="value1"
                           placeholder="单位"
                           @change="handleFilterChange()">
                    <el-option
                            v-for="item in institution"
                            :key="item.institutionId"
                            :label="item.institutionName"
                            :value="item.institutionId">
                    </el-option>
                </el-select>
            </div>
            <div class="select-option">
                <el-select v-model="value2" placeholder="批次" @change="handleFilterChange()">
                    <el-option
                            v-for="item in batch"
                            :key="item.batchId"
                            :label="item.batchName"
                            :value="item.batchId">
                    </el-option>
                </el-select>
            </div>
            <div class="select-option">
                <el-select v-model="value3" placeholder="年份" @change="handleFilterChange()">
                    <el-option
                            v-for="item in year"
                            :key="item.batchYear"
                            :label="item.batchYear"
                            :value="item.batchYear">
                    </el-option>
                </el-select>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                institution: [],
                batch: [],
                year: [],
                value1: '',
                value2: '',
                value3: ''
            }
        },
        created() {
            this.$api.visual.getAllBatch().then(res => {
                this.batch = res.data;
            });
            this.$api.visual.getAllInstitution().then(res => {
                this.institution = res.data;
            });
            this.$api.visual.getBatchYear().then(res => {
                this.year = res.data;
            });
        },
        methods:{
            handleFilterChange() {
                console.log(123);
                this.$parent.$emit('filter-change', { institutionId: this.value1, batchId: this.value2, year: this.value3 });
            },
        }
    }
</script>

<style scoped lang="less">
.header{

  .select{
    margin-top: 20px;
     .select-option{
      width: 150px;
      float: left;
      margin-left: 5px;


    }
  }

}
</style>