
<template>
    <div class="container">
      <el-table :data="tableData" ref="table" height="100%"
                :header-cell-style="{color:'#e9e9e9',borderColor:'#1d3a76'}"
                :cell-style="{color:'#e9e9e9',borderColor:'#1d3a76'}"
                :show-overflow-tooltip="true"
                style="width: 100%">
        <el-table-column prop="expertName" label="姓名" width="120" align="center"></el-table-column>
        <el-table-column prop="postTime" label="时间" align="center"></el-table-column>
        <el-table-column prop="stateShow" label="状态" align="center"></el-table-column>
      </el-table>

    </div>

</template>

<script>
export default {
  data() {
    return {
      tableData: [],
    }
  },
  created() {
    this.$api.visual.getExpertAndResult().then(res => {
      this.tableData = res.data;
    });

  },
    mounted() {
        this.infiniteScroll();
        this.start();
    },
    methods: {
        infiniteScroll() {
            const table = this.$refs.table;
            const divData = table.bodyWrapper;

            let t;
            function start() {
                t = setInterval(() => {
                    divData.scrollTop += 1;
                    if (divData.clientHeight + divData.scrollTop === divData.scrollHeight) {
                        divData.scrollTop = 0;
                    }
                }, 40);
            }

            this.start = start; // 将start()方法挂载到组件实例上，以便在其他方法中调用

            divData.addEventListener('mouseenter', () => {
                clearInterval(t);
            });

            divData.addEventListener('mouseleave', () => {
                this.start(); // 调用组件实例上的start()方法，重新开始滚动
            });
        },
    },
};
</script>

<style scoped lang="less">
  .container{
    width: 100%;
    height: 100%;
  }


  /deep/.el-table {
    // width: 100%;
    box-sizing: border-box;
    &::before{
      height: 0px;
    }
  }

  .el-table--border::after, .el-table--group::after{
    width: 0px;
    height: 0px;
  }

  /*最外层透明*/
  /deep/ .el-table, /deep/ .el-table__expanded-cell{
    background-color: transparent;
  }
  /* 表格内背景颜色 */
  /deep/ .el-table th,
  /deep/ .el-table tr,
  /deep/ .el-table td {
    background-color: transparent;
  }
  :deep(.el-table__inner-wrapper::before) {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    z-index: 3;
  }
</style>
