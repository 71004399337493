<template>
<div id="paperPerMon" style="height: 100%; width: 100%"></div>
</template>

<script>
    import * as echarts from "echarts";
    // import eventBus from 'path/to/eventBus.js';
    export default {
        data(){
            return {
            }
        },
        mounted(){
            console.log(456);
            this.$parent.$on('filter-change', this.handleFilterChange);
            this.initChart();
        },
        methods: {
            initChart(){
                this.$api.visual.getPaperCountPerMonth().then(res => {
                    this.loadChart(res.data);
                });
            },
            handleFilterChange(params) {
                const { institutionId, batchId, year } = params;
                console.log(params);
                // 调用接口并获取数据
                this.$api.visual.getPaperCountPerMonthScreen(institutionId, batchId, year).then(res => {
                    this.loadChart(res.data);
                });
            },
            loadChart(data){
                var chartDom = document.getElementById('paperPerMon');
                const myChart = echarts.init(chartDom);// 图标初始化
                let dataList = [];
                let timeList = [];
                for (let i = 0; i < data.length; i++) {
                    dataList.push(data[i].data);
                    timeList.push(data[i].time);
                }
                const option =  {
                    title: {
                        text: '每月送审统计',
                        left: 'center',
                        textStyle: {
                            color: "rgba(255,255,255,0.9)",
                            fontSize: "20"
                        }
                    },
                    tooltip: {
                        axisPointer: {
                            type: 'line',
                            textStyle: {
                                color: "rgba(255,255,255,.6)",
                                fontSize: 12
                            }
                        },
                    },
                    legend: {
                        top: "10%",
                        right: "10%",
                        textStyle: {
                            color: "rgba(255,255,255,.5)",
                            fontSize: "12"
                        }
                    },
                    grid: {
                        left: "10%",
                        top: "25%",
                        right: "15%",
                        bottom: "5%",
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: "rgba(255,255,255,.6)",
                                fontSize: 12
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,.2)"
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,.1)"
                            }
                        },
                        data: timeList,
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            textStyle: {
                                color: "rgba(255,255,255,.6)",
                                fontSize: 12
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,.2)"
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255,255,255,.1)"
                            }
                        }
                    },
                    series: [
                        {
                            data: dataList,
                            type: 'line',
                            smooth: true,
                            name: '送审量',
                            symbol: "circle",
                            symbolSize: 5,
                            lineStyle: {
                                normal: {
                                    color: "#00d887",
                                    width: 2
                                }
                            },
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                                        [
                                            {
                                                offset: 0,
                                                color: "rgba(0, 216, 135, 0.4)"
                                            },
                                            {
                                                offset: 0.8,
                                                color: "rgba(0, 216, 135, 0.1)"
                                            }
                                        ],
                                        false
                                    ),
                                    shadowColor: "rgba(0, 0, 0, 0.1)"
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: "#00d887",
                                    borderColor: "rgba(221, 220, 107, .1)",
                                    borderWidth: 12
                                }
                            },
                        }
                    ]
                };
                myChart.setOption(option);// 渲染页面
                //随着屏幕大小调节图表
                window.addEventListener("resize", () => {
                    myChart.resize();
                });
            }
        }
    }
</script>
