<template>
    <div id="subjectDistribution" style="width:100%;height: 100%;"></div>
</template>

<script>
    // import axios from "axios";
    import * as echarts from "echarts";

    export default {
        data() {
            return {}
        },
        mounted() {
            this.initChart();
            this.loadChart();
        },
        methods: {
            initChart() {
                this.$api.visual.getSubjectDistribution().then(res => {
                    this.loadChart(res.data);
                });
            },
            loadChart(data) {
                var chartDom = document.getElementById('subjectDistribution');
                const myChart = echarts.init(chartDom);// 图标初始化
                var color = ["#004080", "#024a91", "#065aab", "#026eb6", "#066eab", "#0682ab", "#088ab5", "#07acc5", "#0cadc5", "#18bbc7",
                    "#08c0b6", "#03cec3", "#06c8be", "#06c8ab", "#06dcab", "#06f0ab", "#0edeb8", "#11eeac", "#4af5c2", "#7dffcf"];
                const option = {
                    title: {
                        text: '已送审学科分布',
                        left: 'center',
                        textStyle: {
                            color: "rgba(255,255,255,0.9)",
                            fontSize: "20"
                        }
                    },
                    color: color,
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        itemWidth: 10,
                        itemHeight: 10,
                        orient: 'horizontal',
                        x: 'center',
                        y: '60%',
                        textStyle: {
                            color: "rgba(255,255,255,.5)",
                            fontSize: "12"
                        },
                    },
                    series: [
                        {
                            // name: 'Access From',
                            type: 'pie',
                            radius: ['30%', '50%'],
                            //修改饼形图的位置
                            center: ["50%", "35%"],
                            data: data,
                            itemStyle: {
                                borderRadius: 3,
                                borderColor: '#fff',
                                borderWidth: 0.5
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            },
                            label: {
                                normal: {
                                    show: false
                                },
                                labelLine: {
                                    show: false
                                }
                            },
                        }
                    ]
                };
                myChart.setOption(option);// 渲染页面
                //随着屏幕大小调节图表
                window.addEventListener("resize", () => {
                    myChart.resize();
                });

            }
        }
    }

</script>
