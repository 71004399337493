<template>
<div id="main" style="width:100%;height: 100%;">
</div>
</template>


<script>
import * as echarts from 'echarts';
import axios from "axios";
export default{
    data(){
        return{
            num:[]
        }
    },
    mounted(){
        this.initChart();
    },
    methods:{
      initChart(){
        axios.get("http://localhost:9090/getAllTitle").then((result) =>{
        console.log(result)
        this.initbie(result.data);
        // this.num = result.data
        })
      },
      initbie(data){
var chartDom = document.getElementById('main');
var myChart = echarts.init(chartDom);
var option;
option = {
  title: {
    text: '职称分布',
    // subtext: 'Fake Data',
    left: 'center',
      textStyle: {
          color: "rgba(255,255,255,0.9)",
          fontSize: "20"
      }
  },
  tooltip: {
    trigger: 'item'
  },
    legend: {
        itemWidth: 10,
        itemHeight: 10,
        orient: 'horizontal',
        x:'center',
        y:'80%',
        textStyle: {
            color: "rgba(255,255,255,.5)",
            fontSize: "12"
        },
    },
  series: [
    {
        color: ["#065aab", "#0682ab", "#06a0ab", "#06b4ab", "#06dcab"],
        name: '职称',
        type: 'pie',
        radius: '50%',
        data: data,
        avoidLabelOverlap: false,
        label: { show: true },
        labelLine: { show: true},
        emphasis: {
            label: {
                show: true,
                fontSize: 15,
            }
        },
        itemStyle: {
            borderRadius: 1,
            borderColor: '#0e1647',
            borderWidth: 1
        },
    }
  ]
};

option && myChart.setOption(option);



      }
    }
    }


</script>